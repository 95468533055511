import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {BlogContainer} from "../../components/blog/container";
import {BlogMDWrapper} from "../../components/blog/md-wrapper";
import image from './release-train-overview.jpg';
import {MarkdownViewer} from "../../components/markdown-viewer";

const markdown = `
Releasing features and updates is easy when your user base is still small, you’re not dependent on too many other tools and teams, and the codebase is smaller and up-to-date. When you do start to grow, releasing can get exponentially harder very quickly as your team, dependencies, features, and user expectations grow.

Dates start to slip which frustrates developers, users, and leaders and you and your team start spending more time coordinating than building - but it doesn’t have to be this way!

Managing releases across complex products and multiple teams is tricky, and there is more than one right way to do it. From my experience, reading, and building products used by millions of people, this is the approach I find to work best.  These steps are based on my general belief that process in software development should allow teams to move quickly, at their own pace, and reduce dependencies rather than centrally (micro-)managing things. If you want you can read more about this at the end of the post and some problems I think central planning has and why this approach makes sense.

The goals of this framework is to decouple development of features across different teams & tools, so PMs can focus more on building and designing solutions, developers can get their code out quickly and correctly to the people who use it, and leadership can have consistent and stable product releases and feature launches.

There is work upfront to establish the framework and likely an investment needed in better tooling, automation, and testing to build in launch flexibility as well as developing well documented interfaces between systems and even teams.

### Principles
* Teams should strive to build products that reduce dependencies through abstraction and remote configuration to allow them to act autonomously. 

* This means building for backwards and forwards compatibility and even sometimes including multiple versions of a feature that can be toggled through feature flags. 

* Adding in multiple versions of some features for short periods does add some extra code to the project, but adds more flexibility for teams to work at their own pace and keep moving, and the additional benefit of being able to revert products easily if bugs do arise.

* Developers need to build new features so they are testable regardless of dependencies on other tools/products.

* Each product should have a regular release cycle that happens at least every 2 weeks.

* Leadership must accept that some features might be ready to release, but must wait until the release day of a product to allow for consistent QA and launches - this will increase stability and velocity over time.

* QA of a feature must be distinguished from general product QA. Feature QA should happen agnostic of a general product release QA process and should be a prerequisite for that feature being included in an upcoming release.

### Real-world Example
![](${image})

Let’s consider an engineering org with 3 products owned by 3 different teams that all depend on each other to provide a final client experience. 

* Each product can have a distinct release cycle based on their needs, some bi-weekly, some twice a week. 
* Products can have variable QA times required for releases
* Features that will go out with a next release for any project can be merged as soon as the feature is done and tested locally on that product, and can be released in the default "off" state.
* As soon as all feature dependencies are released then a feature can be easily "turned on" through one or multiple remote configs.

Let’s consider the paths of a few example features over a 10 day period for these teams:
Feature 1 (yellow): Single large feature one team works on, takes 5 days of development and can be simple released when the next release goes out 2 days later.

Feature 2 (purple): Feature requiring work across two teams, the backend team can do their work and release the feature in an "off" state before the database team has finished their work. Then once the database team finishes and releases their work, the backend team can toggle on the feature. 

Feature 3 (teal): Very complex feature requiring work across all products, each team can however work at their own pace and dark launch feature in their product. 

Feature 4 (blue): Requires development across two products, team A started development and built a first version and released it, however problems were discovered with the feature so they continued development for a few more days. Product B could make their required changes and dark launch the feature until Product A made their fixes.

### Setup Process
1. Identify your products and teams and their current release cadences
2. Establish QA needs (automated and manual) on a per product basis, determine required test cases for each release (what must we test before a release goes out)
3. Establish roles and responsibilities across all resources, what are the checkpoints? Who is releasing? Who is testing? Is there sign off needed?
4. Fill out the [Product Release Page Template](https://docs.google.com/presentation/d/1c52k_AzstvCO7pzmJLxT-NGStwtD8QeRSDei7npXAd0/edit#slide=id.gd174249f50_0_42) with each team, map general dependencies across products, and add useful links
5. Ensure you can Dark Launch features across any product through the use of remote configs which act as feature toggles. It’s best if Product Managers can have direct access to these tools.

### General Release Cycle
1. Knowing when a product releases and how much manual QA it needs (days) tells us when the submission deadline for features to make it out in that release is.
2. Dev lead creates the Release Candidate which is then tested by QA
3. QA gives the dev lead the all-clear to release to production
4. Dev lead creates the production release
5. If the feature has dependencies on other tools, then toggle those features to off in the remote config. Toggle on when all the feature dependencies are released across products.
6. (Best practice) - Production anomaly detection to test if a release has an impact on metrics / usability.

### Artifacts
* Team Release Process Map - need to know what all the products are, which team owns it (and who is responsible for releasing), when it releases (day, cadence). Need to establish general QA needs (required tests) and time needed. Also lists out duties of the roles, e.g. QA tester gives all clear to dev for prod release.
* Regular Release Calendar - for all products: when the prod push goes out, what manual QA needs to happen, what automated tests pass
* Feature release process - can vary depending on the size/scope of a feature, some may be affect only one product, some may affect all even in multiple phases
* Feature Test Plans - specific tests to test out a new feature (may roll into product test plans as they’re released)
* Product Test Plans - general tests done before each release

### Roles
* Dev lead is on a per product basis and keeps an overview of what is in the next releases.
* Developers need to let the dev lead know if a feature/PR will make it into a release.
* QA testers need to test a release submission.
* Product manager: has an overview of a single feature and controls toggling it on/off across products.

### Next Steps
As your team, products, and user base grows, work to increase the frequency of releases to make releasing an easy thing for anyone and reduce the need for hotfixes as a new release will go out soon. Invest heavily in automated testing (unit, integration, end-to-end) and anomaly detection.

### Philosophy
* Two general approaches to software development: centrally planned and autonomous relatively small teams. The former can work in the short term but wears on everyone and slows things down in the long term. The latter is a better solution for big or small companies. 
* This is harder at legacy companies, because startups naturally develop in a small and lean way and legacy orgs already have a lot of structure which tends to lead to release and development processes being built simply around those (Conways law).

### Problems with central planning
* PRs sit in open statuses which causes more dev work
* Lack of regular releases and releases tied to features leads to an increased need for hotfixes.
* It’s nearly impossible to catch all issues across products as features and complexity grows, and slowing all teams down for one issue is a waste of time and valuable resources.

### Research / Sources
* [The Release Train Has Crashed Into the Station](https://www.cloudbees.com/blog/release-train-crashed/)
* [You Don’t Have to Be Facebook to Ship Software Like Facebook](https://www.cloudbees.com/blog/dont-facebook-ship-software-like-facebook/)
* [Rapid release at massive scale - Facebook Engineering](https://engineering.fb.com/2017/08/31/web/rapid-release-at-massive-scale/)`;

export default function BlogReleaseTrains() {
    return (
        <Page menu>
            <Header subtitle="Release Trains" title='All aboard! Build a product release process like the pros.'
                    description='Simple ways that help you ship better code faster.'
            />
            <BlogContainer>
                <BlogMDWrapper>
                    <MarkdownViewer source={markdown}></MarkdownViewer>
                </BlogMDWrapper>
            </BlogContainer>
        </Page>
    )
};